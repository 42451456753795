import React from "react"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer has-background-dark has-text-light">
      <div className="container">
        <small>
          <p>
            &copy; {currentYear} Anisotropia. <br />
            {/* Hecho con{" "}
              <a href="https://bulma.io">
                <b>Bulma</b>
              </a>{" "}
              y{" "}
              <a href="https://gatsbyjs.org">
                <b>GatsbyJS</b>
              </a>
              . */}
          </p>
          {/* <br /> */}
        </small>
      </div>
    </footer>
  )
}

export default Footer
