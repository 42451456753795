import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import { useSiteMetadata } from "./Socials"

//icons
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa"

class HeaderNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMenuActive: false }
  }

  componentWillMount() {
    if (typeof document !== "undefined")
      document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return
    }

    this.setState({ isMenuActive: false })
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuActive: !prevState.isMenuActive,
    }))
  }

  onClickLink = e => {
    this.toggleMenu()
  }

  render() {
    const routes = [
      { name: "Ani", route: `/#ani` },
      { name: "Videos", route: `/#videos` },
      { name: "Acoge a un científico", route: `/#acogeauncientifico` },
      { name: "Contacto", route: `/#contacto` },
      { name: "Blog", route: `/blog` },
    ]

    // const { twitter, facebook, instagram, youtube } = useSiteMetadata()

    // const socials = [
    //   {
    //     icon: <FaFacebookSquare />,
    //     link: "https://www.facebook.com/Anideanisotropia/",
    //   },
    //   {
    //     icon: <FaInstagram />,
    //     link: "https://www.instagram.com/anisotropia/",
    //   },
    //   { icon: <FaTwitter />, link: "https://twitter.com/Anianisotropia" },
    //   {
    //     icon: <FaYoutube />,
    //     link: "https://www.youtube.com/channel/UCWr1j8v5QUFVoMMaweOnGBw/",
    //   },
    // ]
    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                fixed(height: 28) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            site {
              siteMetadata {
                social {
                  twitter
                  facebook
                  instagram
                  youtube
                }
              }
            }
          }
        `}
        render={data => {
          const {
            facebook,
            instagram,
            twitter,
            youtube,
          } = data.site.siteMetadata.social
          const socials = [
            {
              icon: <FaFacebookSquare />,
              //link: "https://www.facebook.com/Anideanisotropia/",
              link: `https://www.facebook.com/${facebook}/`,
            },
            {
              icon: <FaInstagram />,
              //link: "https://www.instagram.com/anisotropia/",
              link: `https://www.instagram.com/${instagram}/`,
            },
            { icon: <FaTwitter />, link: `https://twitter.com/${twitter}` },
            {
              icon: <FaYoutube />,
              // link: "https://www.youtube.com/channel/UCWr1j8v5QUFVoMMaweOnGBw/",
              link: `https://www.youtube.com/channel/${youtube}/`,
            },
          ]
          return (
            <nav
              className="navbar is-fixed-top is-primary"
              role="navigation"
              aria-label="main navigation"
              ref={node => (this.node = node)}
            >
              <div className="navbar-padding" />
              <div className="navbar-brand ">
                <Link className="navbar-item is-uppercase" to="/">
                  <Img fixed={data.file.childImageSharp.fixed} alt="logo" />
                  <span style={{ marginLeft: "0.5rem" }}>Anisotropía</span>
                </Link>
                <div
                  className={`navbar-burger burger ${
                    this.state.isMenuActive ? "is-active" : ""
                  }`}
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarMenu"
                  onClick={this.toggleMenu}
                  onKeyPress={this.toggleMenu}
                  tabIndex={0}
                  role="button"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div
                id="navbarMenu"
                className={`navbar-menu ${
                  this.state.isMenuActive ? "is-active" : ""
                }`}
              >
                <div className="navbar-end">
                  {routes.map(({ name, route }, index) => (
                    <Link
                      key={index}
                      to={route}
                      className="navbar-item is-uppercase"
                      activeClassName="is-active"
                      onClick={this.onClickLink}
                    >
                      {name}
                    </Link>
                  ))}
                  {socials.map((red, index) => (
                    <a
                      href={red.link}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="is-hidden-touch center"
                      style={{ margin: 5, padding: 0 }}
                    >
                      <div>
                        <span className="is-size-5 has-text-white">
                          {red.icon}
                        </span>
                      </div>
                    </a>
                  ))}
                </div>
              </div>

              <div className="navbar-padding" />
            </nav>
          )
        }}
      />
    )
  }
}

export default HeaderNav
