import React from "react"

import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const FixedFooterStyle = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`

const FixedFooter = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            social {
              twitter
              facebook
              instagram
              youtube
            }
          }
        }
      }
    `
  )

  const { twitter, facebook, instagram, youtube } = site.siteMetadata.social
  const socials = [
    {
      icon: <FaFacebookSquare />,
      link: `https://www.facebook.com/${facebook}/`,
    },
    {
      icon: <FaInstagram />,
      link: `https://www.instagram.com/${instagram}/`,
    },
    { icon: <FaTwitter />, link: `https://twitter.com/${twitter}` },
    {
      icon: <FaYoutube />,
      link: `https://www.youtube.com/channel/${youtube}/`,
    },
  ]

  return (
    <FixedFooterStyle className="level is-mobile is-hidden-desktop has-background-primary">
      {socials.map((red, index) => (
        <div key={index} className="level-item">
          <a
            href={red.link}
            aria-label="Follow me"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="is-size-3" style={{ margin: 5 }}>
              {red.icon}
            </div>
          </a>
        </div>
      ))}
    </FixedFooterStyle>
  )
}

export default FixedFooter
